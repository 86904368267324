<template>
  <div
    class="absolute bg-white shadow-md min-w-80 px-4 py-2 z-10"
    data-cy="app-token-modal"
    :style="positionStyle"
  >
    <div class="flex flex-col space-y-2">
      <div
        v-for="inputGroup in inputGroups"
        :key="inputGroup.key"
        class="flex flex-col space-y-2"
      >
        <lf-h4>
          {{ getInputGroupTitle(inputGroup.key) }}
        </lf-h4>
        <div class="max-h-30 overflow-y-auto flex flex-col space-y-2">
          <div
            v-for="(input, index) in inputGroup.inputs"
            :key="input.id"
            class="flex gap-x-2"
          >
            <input
              :value="input.value"
              ref="inputBlock"
              name="value"
              :data-cy="`app-${inputGroup.key}-token-value-${index}`"
              class="text-sm placeholder-gray-400 rounded hover:border-gray-300 focus:border-primary focus:outline-none focus:ring-0 border border-gray-200 grow"
              :placeholder="$t('COMMON.TOKEN')"
              @input="input.value = ($event.target as HTMLInputElement)?.value"
            />
            <div class="min-w-20">
              <div v-if="!!input.value" class="gap-x-2 flex items-center">
                <div class="min-w-8">
                  <button
                    v-if="index"
                    v-tooltip="`${$t('COMMON.DELETE')} ${$t('COMMON.TOKEN')}`"
                    type="button"
                    :data-cy="`app-delete-${inputGroup.key}-token-value-${index}`"
                    class="p-2 rounded-4 w-fit-content justify-self-end"
                    @click="deleteInput(input.id, inputGroup.key)"
                  >
                    <icon-base :icon="IconDelete" />
                  </button>
                </div>
                <button
                  v-if="index === inputGroup.inputs.length - 1"
                  v-tooltip="`${$t('COMMON.ADD')} ${$t('COMMON.TOKEN')}`"
                  type="button"
                  :data-cy="`app-add-${inputGroup.key}-token-value-${index}`"
                  class="p-2 rounded-4 w-fit-content justify-self-end"
                  @click="addInput(inputGroup.key)"
                >
                  <icon-base :icon="IconAdd" :width="20" :height="20" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex items-center justify-end space-x-3">
        <button
          :class="hasSomeValue ? 'text-primary' : 'text-disabled-medium'"
          data-cy="app-token-save"
          :disabled="!hasSomeValue"
          @click.prevent="saveValues"
        >
          {{ $t("COMMON.SAVE") }}
        </button>
        <button
          class="text-error"
          data-cy="app-token-cancel"
          @click.prevent="emit('value:save', '')"
        >
          {{ $t("COMMON.CANCEL") }}
        </button>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import LfH4 from "@/components/ui/text/LfHeading4.vue";
import IconBase from "@/components/ui/IconBase.vue";
import IconAdd from "@/components/icons/deals/IconAdd.vue";
import IconDelete from "@/components/icons/IconDelete.vue";

import { computed, nextTick, onMounted, ref } from "vue";
import { v4 } from "uuid";
import { appTrackingTokenKey, utmSourceKey } from "@/helpers/textEditor";
import { useI18n } from "vue-i18n";
const { position } = defineProps<{
  position: {
    bottom: number;
    left: number;
    right: number;
    top: number;
    height: number;
  };
}>();

type InputsKey = "tracking" | "utm";

const emit = defineEmits<{
  "value:save": [value: string];
}>();

const barHeight = 45;
const leftOffset = 20;

const { t } = useI18n();

const inputBlock = ref<HTMLInputElement[] | null>(null);

const inputGroups = ref<
  { key: InputsKey; inputs: { value: string; id: string }[] }[]
>([
  { key: "tracking", inputs: [{ value: "", id: v4() }] },
  { key: "utm", inputs: [{ value: "", id: v4() }] }
]);

const hasSomeValue = computed(() =>
  inputGroups.value.flatMap(({ inputs }) => inputs).some(({ value }) => !!value)
);

const positionStyle = computed(() => {
  const topPositon = position.top + position.height + barHeight;
  const leftPosition = position.left - leftOffset;
  return {
    top: `${topPositon}px`,
    left: `${leftPosition}px`
  };
});

const saveValues = () => {
  const tokensWithValues = inputGroups.value.reduce(
    (tokens: string[], { inputs }, groupIndex) => {
      const token = groupIndex ? utmSourceKey : appTrackingTokenKey;

      const values = inputs.reduce((res: string[], { value }) => {
        if (!value) {
          return res;
        }

        res.push(`${token}${value}`);
        return res;
      }, []);

      tokens.push(...values);
      return tokens;
    },
    []
  );

  const value = `__${tokensWithValues.join(",")}`;

  emit("value:save", value);
};

const getInputGroupTitle = (key: InputsKey) => {
  return key === "utm"
    ? t("ORGANIZATION.COMMUNICATION_TEMPLATES.ENTER_UTM_PARAMETER")
    : t("ORGANIZATION.COMMUNICATION_TEMPLATES.ENTER_APP_TRACKING_TOKEN_LINK");
};

const addInput = (key: InputsKey) => {
  const group = inputGroups.value.find((group) => group.key === key);

  if (!group) {
    return;
  }

  group.inputs.push({ value: "", id: v4() });
  nextTick(() => {
    inputBlock.value?.[inputBlock.value.length - 1]?.focus();
  });
};

const deleteInput = (id: string, key: InputsKey) => {
  const group = inputGroups.value.find((group) => group.key === key);

  if (!group) {
    return;
  }

  const index = group.inputs.findIndex((input) => input.id === id);

  if (index === -1) {
    return;
  }

  group.inputs.splice(index, 1);
};

onMounted(() => {
  inputBlock.value?.[0]?.focus();
});
</script>
